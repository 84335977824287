<!-- 校务管理 > 宿舍管理 > 宿舍分配(宿舍) -->
<template>
    <div>
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class=""
            >
                <div style="padding-right: 10px;">
                    <statistics-data
                    :statistics-data="statisticsData"
                    style="margin-top: 0px;"
                ></statistics-data>
                </div>

                <div class="content-main">
                    <div class="filter-wrap">
                        <expand-filter
                            :closeWidth="510"
                            marginBottom="0px"
                            :formData="formData"
                            @clickBtn="clickBtn"
                            @changeBtnFormType="changeBtnFormType"
                            @changeSel="changeSel"
                        >
                        </expand-filter>
                        <!-- <div class="button-line"></div> -->
                        <div
                            v-hasPermi="[
                                'dormitoryDistribution:dormitory:displayEmptyBeds',
                            ]"
                            class="empty-beds"
                        >
                            <el-checkbox
                                @change="emptyBedsChange"
                                v-model="showEmptyBeds"
                                >显示有空床位宿舍</el-checkbox
                            >
                        </div>
                    </div>
                    <table-data
                        ref="table"
                        :to-top="false"
                        v-loading="loadingTable"
                        :config="table_config"
                        :tableData="table_data"
                        @handleSelectionChange="handleTableSelectionChange"
                    >
                        <template v-slot:dormitory="slotData">
                            <div class="dormitory-name">
                                <span @click="showRoomMessage(slotData.data)">{{
                                    slotData.data.roomName
                                }}</span>
                            </div>
                        </template>
                        <template v-slot:operation="slotData">
                            <el-button
                                type="primary"
                                size="mini"
                                @click="handleDistribution(slotData.data)"
                                v-hasPermi="[
                                    'dormitoryDistribution:dormitory:distribution',
                                ]"
                                >分配
                            </el-button>
                        </template>
                    </table-data>
                    <pagination
                        :total="total"
                        :page.sync="listQuery.pageNum"
                        :limit.sync="listQuery.pageRow"
                        @pagination="changeGetList"
                    />

                    <!-- 分配第一层 -->
                    <dialog-wrapper
                        :dialogObj="distributionObjOne"
                        @handleClose="handleDistributionCloseOne"
                    >
                        <DialogTableSort
                            :loading="loadingOne"
                            :studentObj="studentObj"
                            @distribution="distributionBtn"
                            @handleClose="handleDistributionCloseOne"
                            @primary="primaryBtn"
                        />
                    </dialog-wrapper>
                    <!--分配-->
                    <dialog-wrapper
                        :dialogObj="distributionObj"
                        @handleClose="handleDistributionClose"
                    >
                        <distribution-form-data
                            ref="distributionRef"
                            :options-data="optionsData"
                            :form="distributionListQuery"
                            :list="distributionTableData"
                            :empyCount="empyCount"
                            @getStuTableList="getStuTableList"
                            @handleClose="handleDistributionClose"
                        />
                    </dialog-wrapper>

                    <!-- 详情 -->
                    <dialog-wrapper
                        :dialogObj="roomMessageDialog"
                        @handleClose="roomMessageDialogClose"
                    >
                        <DialogTableShow
                            :loading="loadingOne"
                            :studentObj="studentObj"
                            @handleClose="roomMessageDialogClose"
                        />
                    </dialog-wrapper>
                </div>
            </div>
        </div>
        <error :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
    // 功能组件
    Error,
    Loading,
    // 工具函数
    debounce,
} from "common-local";
import {mapState} from "vuex";
import {downloadFile, listToTree, formatTreeData} from "@/libs/utils.js";
import {SchoolDormitory} from "@/models/SchoolDormitory";
import ExpandFilter from "../Sub/ExpandFilter";
import dialogFormData from "../SchoolDormitory/dormitoryManage/dialogFormData";
import statisticsData from "../SchoolDormitory/dormitoryManage/statisticsData";
import distributionFormData from "../SchoolDormitory/dormitoryManage/distributionFormData";
import TableData from "../Sub/TableData";
import onResize from "@/mixins/onResize";
import RecordsList from "@/libs/pageListOptimal.js";
import DialogTableSort from "./DormitoryDistribution/DialogTableSort.vue";
import DialogTableShow from "./DormitoryDistribution/DialogTableShow.vue";
export default {
    name: "DormitoryDistribution",
    mixins: [onResize],
    components: {
        TableData,
        DialogWrapper,
        // 功能组件
        Error,
        Loading,
        Pagination,
        ExpandFilter,
        dialogFormData,
        statisticsData,
        distributionFormData,
        DialogTableSort,
        DialogTableShow,
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    data() {
        return {
            // 筛选查询参数
            listQuery: {
                schoolId: "",
                roomType: "",
                organIdList: [],
                dormitoryId: "",
                floorId: "",
                roomName: "",
                pageNum: 1,
                pageRow: 20,
                grade: "",
                displayEmptyBeds: "",
            },
            showEmptyBeds: false,
            subjects: [],
            total: 0,
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "宿舍楼",
                        key: "dormitoryId",
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "楼层",
                        key: "floorId",
                        list: [],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "宿舍",
                        key: "roomName",
                    },
                    {
                        type: "cascader",
                        label: "",
                        value: [],
                        placeholder: "班级",
                        key: "organIdList",
                        cascaderProps: {
                            label: "name",
                            value: "id",
                            emitPath: false,
                            multiple: true,
                        },
                        list: [],
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "宿舍类型",
                        key: "roomType",
                        list: [
                            {label: "男", value: "1"},
                            {label: "女", value: "2"},
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["dormitoryDistribution:dormitory:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["dormitoryDistribution:list"],
                    },
                ],
                btnFormType: true,
            },
            // 表格配置
            table_config: {
                thead: [
                    {
                        label: "宿舍名",
                        prop: "roomName",
                        type: "slot",
                        slotName: "dormitory",
                        labelWidth: "150px",
                        align: "center",
                    },
                    {
                        label: "宿舍楼",
                        prop: "dormitoryName",
                        align: "center",
                    },
                    {
                        label: "楼层",
                        prop: "floorName",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "班级",
                        prop: "classList",
                        type: "tooltipColumn",
                        labelWidth: "150px",
                        splitSign: ",",
                        align: "center",
                        className: "text-spacing-reduction",
                        // formatter:function(row,column,cellValue,index){
                        // 	return cellValue?cellValue.join("; "):''
                        // },
                    },
                    {
                        label: "床位数",
                        prop: "bedNum",
                        align: "center",
                    },
                    {
                        label: "剩余床位",
                        prop: "remainingNum",
                        sortable: true,
                        labelWidth: "160px",
                        align: "center",
                    },
                    {
                        label: "宿舍类型",
                        prop: "roomType",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            return {
                                1: "男",
                                2: "女",
                            }[+row.roomType];
                        },
                    },
                    {
                        label: "更新时间",
                        prop: "distributeTime",
                        sortable: true,
                        labelWidth: "200px",
                        align: "center",
                        type: "function",
                        callBack(row) {
                            return row.distributeTime
                                ? row.distributeTime.slice(0, 16)
                                : "-";
                        },
                    },
                    // {
                    //     label: "创建时间",
                    //     prop: "createTime",
                    //     labelWidth: '160px',
                    //     align: "left",
                    //     type: "function",
                    //     callBack(row) {
                    //         return row.createTime ? row.createTime.slice(0, 16):'-'
                    //     },
                    // },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "200",
                        slotName: "operation",
                        className: "text-spacing-reduction",
                    },
                ],
                check: true,
                height: "",
            },
            statisticsData: {
                bedAllNum: "",
                roomAllNum: "",
                remainingAllNum: "",
            },
            // 表格数据
            table_data: [],
            // 弹出框配置
            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            // 功能组件
            errorShow: false,
            loadingShow: false,
            loadingTable: true,
            // 新增
            ruleForm: {
                id: "",
                schoolId: "",
                dormitoryId: "",
                floorId: "",
                roomName: "",
                roomType: "1",
                bedNum: undefined,
            },
            dormitoryList: [], //宿舍楼信息
            floorList: [],
            tableListSel: [],
            // ---------------弹窗相关---------------
            // 分配弹窗第一层
            distributionObjOne: {
                title: "分配-",
                dialogVisible: false,
                width: "900px",
            },
            loadingOne: false,
            empyCount: 0,
            // 查询当前宿舍的学生参数
            studentForm: {
                schoolId: "",
                roomId: "",
                dormitoryId: "",
                floorId: "",
            },
            // 当前宿舍的学生列表
            studentsList: [],
            studentObj: {
                studentsList: [],
                maxNum: 0,
            },
            // 分配第二层
            distributionObj: {
                title: "分配",
                dialogVisible: false,
                width: "900px",
            },

            // 详情弹窗
            roomMessageDialog: {
                title: "详情",
                dialogVisible: false,
                width: "900px",
            },
            // ------------------------------------

            selectDialogOneData: null,
            distributionListQuery: {
                schoolId: "",
                floorId: "",
                dormitoryId: "",
                sex: "",
                allocationStatus: "",
                roomId: "",
                roomName: "",
                organList: [],
                laveBedNym: "",
            },
            distributionTableData: [],
            distributionTableSel: [],
            optionsData: [],
            firstTime: true,
        };
    },
    created() {
        this.firstTime = false;
        this.ruleForm.schoolId = this.listQuery.schoolId = this.schoolId;
        this.init("created");
    },
    mounted() {},
    activated() {
        if (this.firstTime) {
            this.init("activated");
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    methods: {
        async primaryBtn(data) {
            // 调用接口
            // 同步调用
            let roomMessage = JSON.parse(JSON.stringify(this.studentForm));
            data = data.map((item) => {
                return {
                    ...item,
                    ...roomMessage,
                };
            });
            let obj = {
                id: this.studentForm.roomId,
                list: data,
            };
            let result = await this.getDormitorySortSave(obj);
            if (result.data.code !== "200") {
                this.$message.error(result.data.msg);
                this.handleDistributionCloseOne();
                return;
            }
            // 关闭弹窗
            this.handleDistributionCloseOne();
            // 刷新列表
            this.getList();
            // 提示
        },
        getDormitorySortSave(data) {
            const schoolDormitory = new SchoolDormitory();
            return schoolDormitory.getSchoolDormitorySortSave(data);
        },
        emptyBedsChange(val) {
            if (val) {
                this.listQuery.displayEmptyBeds = "1";
            } else {
                this.listQuery.displayEmptyBeds = "";
            }
            this.getList(1);
        },
        init(type) {
            let recordsList = new RecordsList();
            if (recordsList.get(this.$route.name)) {
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
            console.log("this.formData1111111", this.formData);
            this.getRoomTotal(); // 获取宿舍概况数据
            this.getList();
            this.getDormitoryList();
            this.getOrgList();
        },
        // 获取宿舍概况数据
        getRoomTotal() {
            const schoolDormitory = new SchoolDormitory();
            schoolDormitory
                .getRoomTotalDistribution(this.listQuery)
                .then((res) => {
                    console.log("[getRoomTotal]res:", res);
                    this.statisticsData.bedAllNum = res.data.data.bedAllNum;
                    this.statisticsData.remainingAllNum =
                        res.data.data.remainingAllNum;
                    this.statisticsData.roomAllNum = res.data.data.roomAllNum;
                });
        },
        datePickerBlur() {},
        inputChange() {},
        changeGetList() {
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            });
            this.getList();
        },
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            const schoolDormitory = new SchoolDormitory();
            schoolDormitory.getDormitoryList(this.listQuery).then((res) => {
                if (res.data.code === "200") {
                    this.table_data = res.data.data.list;
                    this.total = res.data.data.totalCount;
                    //获取--存储筛选项及滚动条高度
                    this.$nextTick(() => {
                        let recordsList = new RecordsList();
                        if (recordsList.get(this.$route.name)) {
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop =
                                recordsList.get(
                                    this.$route.name,
                                ).tableScrollTop;
                        }
                        console.log(
                            recordsList.get(this.$route.name),
                            "获取--存储筛选项及滚动条高度",
                        );

                        //移除--存储筛选项及滚动条高度
                        recordsList.remove(this.$route.name);
                    });
                } else {
                    this.$message.error(res.data.msg);
                }
                this.getRoomTotal(); //每次调用列表接口，获取宿舍概况数据
                this.loadingTable = false;
            });
        },
        // 获取当前宿舍的学生列表
        getStudentList() {
            this.studentsList = [];
            this.studentObj.studentsList = [];
            this.loadingOne = true;
            const schoolDormitory = new SchoolDormitory();
            schoolDormitory
                .getSchoolDormitoryStudentList(this.studentForm)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.studentsList = res.data.data;
                        this.studentObj.studentsList = JSON.parse(
                            JSON.stringify(res.data.data),
                        );
                    } else {
                        this.$message.error(res.data.msg);
                    }

                    this.loadingOne = false;
                });
        },
        getDormitoryList() {
            const schoolDormitory = new SchoolDormitory();
            schoolDormitory
                .getSchoolDormitoryListCondition({
                    schoolId: this.schoolId,
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        this.dormitoryList = res.data.data.map((item) => ({
                            label: item.dormitoryName,
                            value: item.id,
                        }));
                        this.formData.data.forEach((item) => {
                            if (item.key === "dormitoryId") {
                                item.list = this.dormitoryList;
                            }
                        });
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        getOrgList() {
            let obj = {
                schoolId: this.schoolId,
                jobScope: ["1", "2", "3"],
            };
            this._fet("/school/schoolOrgan/listAuthByConditionSort", obj).then(
                (res) => {
                    if (res.data.code === "200") {
                        const list = res.data.data.list;
                        const listClass = list.filter(
                            (it) => it.organType != 1,
                        );
                        let listArr = formatTreeData(
                            listClass,
                            "id",
                            "parentOrg",
                        );
                        this.optionsData = listArr;
                        this.formData.data.forEach((item) => {
                            if (item.key === "organIdList") {
                                item.list = listArr;
                                this.organIdList = item.list;
                            }
                        });
                    } else {
                        this.$message.error(res.data.msg);
                    }
                },
            );
        },
        clickBtn(data) {
            switch (data.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key == "grade") {
                            return;
                        }
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                case "reset": // 重置
                    this.formData.data.forEach((item) => {
                        if (item.key === "organIdList") {
                            this.listQuery[item.key] = [];
                            item.value = [];
                        } else if (item.key === "floorId") {
                            this.listQuery[item.key] = "";
                            item.value = "";
                            item.list = [];
                        } else {
                            this.listQuery[item.key] = "";
                            item.value = "";
                        }
                        this.floorList = [];
                    });
                    this.getList(1);
                    break;
            }
        },
        /**
         * @Description: 班级 /年级 选中对应节点
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-07-24 14:51:02
         * @param {*} data
         */
        // 改变头部筛选框中下拉框的值时触发
        changeSel(data) {
            if (data.key === "dormitoryId") {
                this.formData.data.forEach((item) => {
                    if (item.key === "floorId") {
                        item.list = [];
                        item.value = "";
                    }
                });
                if (data.value) {
                    this.formData.data.forEach(async (item) => {
                        if (item.key === "floorId") {
                            item.list = await this.getFloorList(data.value);
                        }
                    });
                }
            }
        },
        getFloorList(data) {
            const schoolDormitory = new SchoolDormitory();
            return new Promise((resolve) => {
                schoolDormitory
                    .getSchoolDormitoryFloorListCondition({
                        dormitoryId: data,
                        schoolId: this.schoolId,
                    })
                    .then((res) => {
                        if (res.data.code === "200") {
                            resolve(
                                res.data.data.map((item) => ({
                                    label: item.floorName,
                                    value: item.id,
                                })),
                            );
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
            });
        },
        /**
         * @Description: 对应节点 赋值给查询条件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-07-24 14:52:00
         * @param {*} data
         */
        nodeCLick(data, dataParent) {
            console.log(data, dataParent, "nodeCLickdata");
            switch (data.organType) {
                case "3":
                    this.listQuery.grade = data.name;
                    break;
                case "4":
                    this.listQuery.grade = dataParent.name + data.name;
                    break;
                default:
                    this.listQuery.grade = "";
                    break;
            }
        },
        handleClose() {
            this.dialogObj.dialogVisible = false;
            this.$refs.ruleForm.$refs.ruleForm.resetFields();
            this.floorList = [];
        },
        async dormitoryChange(data) {
            this.floorList = [];
            this.ruleForm.floorId = "";
            if (data) {
                this.floorList = await this.getFloorList(data);
                this.getDialogTableList();
            } else {
                this.dialogTableList = [];
            }
        },

        /*
                   表格相关操作
                   handleTableSelectionChange   表格选中
                   handleDistribution           分配第一层弹窗
                 */
        handleTableSelectionChange(data) {
            this.tableListSel = data;
        },
        showRoomMessage(data) {
            let {
                id,
                dormitoryId,
                floorId,
                roomName,
                dormitoryName,
                floorName,
                roomType,
                bedNum,
            } = data;

            this.studentForm.schoolId = this.schoolId;
            this.studentForm.roomId = id;
            this.studentForm.dormitoryId = dormitoryId;
            this.studentForm.floorId = floorId;

            this.getStudentList();

            this.studentObj.maxNum = JSON.parse(JSON.stringify(bedNum));
            //拼接宿舍信息文字
            let str = `详情-${dormitoryName}/${floorName}/${roomName} (${
                roomType == 1 ? "男" : "女"
            })`;
            this.roomMessageDialog.title = str;
            this.roomMessageDialog.dialogVisible = true;
        },
        handleDistribution(data) {
            let {
                id,
                dormitoryId,
                floorId,
                roomName,
                dormitoryName,
                floorName,
                roomType,
                bedNum,
            } = data;

            this.studentForm.schoolId = this.schoolId;
            this.studentForm.roomId = id;
            this.studentForm.dormitoryId = dormitoryId;
            this.studentForm.floorId = floorId;

            this.getStudentList();

            //拼接宿舍信息文字
            let str = `分配-${dormitoryName}/${floorName}/${roomName} (${
                roomType == 1 ? "男" : "女"
            })`;
            this.distributionListQuery.sex = roomType;
            this.distributionObjOne.title = str;
            this.distributionObj.title = str;
            this.studentObj.maxNum = JSON.parse(JSON.stringify(bedNum));
            this.distributionObjOne.dialogVisible = true;
        },
        // 分配第二层
        distributionBtn(obj) {
            let {nullCount} = obj;
            this.empyCount = JSON.parse(JSON.stringify(nullCount));
            this.distributionObj.dialogVisible = true;
            this.distributionListQuery.schoolId = this.schoolId;
        },

        handleDelete(arr) {
            this.$confirm("确定要执行删除操作吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const schoolDormitory = new SchoolDormitory();
                    schoolDormitory
                        .deleteSchoolDormitoryRoom(arr)
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("删除成功");
                                this.getList();
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        });
                })
                .catch(() => {});
        },
        /*
                    分配
                    getStuTableList              获取学生
                    handleDistributionClose      关闭
                 */
        getStuTableList(data) {
            if (data.organList.length === 0) {
                return (this.distributionTableData = []);
            }
            const obj = {
                schoolId: data.schoolId,
                dormitoryId: data.dormitoryId,
                floorId: data.floorId,
                roomId: data.roomId,
                organList: this.$refs.distributionRef.$refs.cascaderRef
                    .getCheckedNodes()
                    .filter((i) => i.data.organType === "4")
                    .map((i) => i.data.id),
                sex: data.sex,
                allocationStatus: data.allocationStatus,
            };
            const schoolDormitory = new SchoolDormitory();
            schoolDormitory.getStuListByDormitory(obj).then((res) => {
                if (res.data.code === "200") {
                    this.distributionTableData = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        handleDistributionClose() {
            this.distributionObj.dialogVisible = false;

            // 关闭弹窗时清空数据
            let data = this.$options.data();
            let sex = this.distributionListQuery.sex;
            this.distributionListQuery = {...data.distributionListQuery, sex};
            this.distributionTableData = [];
        },
        // 分配关闭弹窗第一层
        handleDistributionCloseOne() {
            this.distributionObjOne.dialogVisible = false;
            this.dialogOneDataInit();
        },
        dialogOneDataInit() {
            let options = this.$options.data();
            this.studentForm = options.studentForm;
        },
        roomMessageDialogClose() {
            this.roomMessageDialog.dialogVisible = false;
            this.dialogOneDataInit();
        },
        routerFloor() {
            //存储筛选项及滚动条高度
            let recordsList = new RecordsList();
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery: this.listQuery,
                tableScrollTop:
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop,
            });
            console.log(
                recordsList.get(this.$route.name),
                "获取--存储筛选项及滚动条高度",
            );
            this.$emit("changeType", "floor");
        },
    },
};
</script>

<style scoped lang="scss">
.filter-wrap {
    position: relative;
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: auto;
    margin-bottom: 10px;
    border-radius: 4px;

    .expand-filter {
        padding: 0;
    }

    .empty-beds {
        position: absolute;
        height: 30px;
        right: 15px;
        padding-top: 2px;
        padding-left: 10px;
        background-color: white;
    }
}

.right-panel {
    margin: 10px 0;
    border-radius: 6px;
    overflow: hidden;
}

.el-cascader-menu:last-child {
    height: 200px;
}

// ::v-deep .table-data .el-table .caret-wrapper{
//     position: relative !important;
//     top: 0 !important;
// }
.dormitory-name {
    span {
        color: #3c7fff;
        cursor: pointer;
        user-select: none;
    }
}
</style>
