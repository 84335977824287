<!--
 * @Author: wangmengyao wang_my991127@163.com
 * @Date: 2023-09-27 14:10:57
 * @LastEditors: wangmengyao wang_my991127@163.com
 * @LastEditTime: 2023-09-27 16:02:04
 * @FilePath: \cloud_campus_Front-end\school_end\src\components\scrollWrapper\SchoolManageDormitoryManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 校务管理 > 宿舍管理 > 宿舍管理 -->
<template>
    <div>
        <dormitory-list @setPageInitTabs="setPageInitTabs" v-if="type === 'list'" @changeType="changeType"></dormitory-list>
        <dormitory-floor @setPageInitTabs="setPageInitTabs" v-else @changeType="changeType"></dormitory-floor>
    </div>
</template>

<script>

export default {
    components: {
        DormitoryList: () => import(/* webpackChunkName:'DormitoryList' */"@/components/scrollWrapper/SchoolManageDormitoryManage/DormitoryList.vue"),
        DormitoryFloor: () => import(/* webpackChunkName:'DormitoryFloor' */"@/components/scrollWrapper/SchoolManageDormitoryManage/DormitoryFloor.vue"),
    },
    data() {
        return {
            type: "list",
            types:'list'
        }
    },
    activated () {
        console.log('=-------')
        this.setGlobalPageType(this.types)
    },
    methods: {
        changeType(type) {
            this.type = type
            if(type === 'list') {
                this.setGlobalPageType(type)
            } else {
                this.setGlobalPageType('detail')
            }

        },
         /**
          * @Description: 页面状态 list列表 detail二级页面(form)
          * @DoWhat:一级列表页面（list）显示头部导航面包屑，二级页面（detail）、表单（form）不显示头部导航面包屑
          * @UseScenarios:
          * @Attention:
          * @Author: xwn
          * @Date: 2023-07-20 11:58:06
          */
         setGlobalPageType (type) {
            this.types = type
            this.$eventDispatch('setGlobalPageType', type)
         },
         setPageInitTabs(){
            this.changeType('list')
        }
    },
}
</script>

<style scoped lang="scss">
</style>
