<!-- 校务管理 > 宿舍管理 > 宿舍分配 -->
<template>
    <div style="height: calc(100vh - 120px);">
        <tabbed-page :tabList="tabList" @changeTabs="handlerChangeTabs">
        </tabbed-page>
        <component :is="comps[idx]">
        </component>
    </div>
</template>

<script>
import onResize from "@/mixins/onResize"
import TabbedPage from './Sub/TabbedPage/index.vue';
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
import DormitoryDistributionStudent from "@/components/scrollWrapper/SchoolManageDormitoryDistribution/DormitoryDistributionStudent.vue";
import DormitoryDistribution from "@/components/scrollWrapper/SchoolManageDormitoryDistribution/DormitoryDistribution.vue";
export default {
    name: "SchoolManageDormitoryDistribution",
    mixins: [onResize],
    components: {
        TabbedPage,
        DormitoryDistributionStudent,
        DormitoryDistribution
    },
    data() {
        return {
            tabList: [
                {
                    id: "first",
                    title: "按学生",
                    com: 'DormitoryDistributionStudent',
                    sel: true,
                },
                {
                    id: "second",
                    title: "按宿舍",
                    com: 'DormitoryDistribution',
                    vHasPermi: ["dormitory:dormitoryDistribution:dormitory"],
                }
            ],
            comps: [
                'DormitoryDistributionStudent',
                'DormitoryDistribution'
            ],
            idx: 0,
        }
    },
    created() {
        this.init();
    },
    methods: {
        handlerChangeTabs(data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, 'sel', true)
                    this.idx = index;
                } else {
                    this.$set(item, 'sel', false)
                }
            })
        },
        init() {
            this.tabList = tabPageHasAuth(this.tabList);
            this.comps = this.tabList.map(item => item.com);
        },
    },
}
</script>

<style scoped lang="scss"></style>
